<template>
  <div
    v-editable="editable"
    class="cms-hero-banner"
    :class="{ 'cms-hero-banner-home' : isHome, 'clickable-banner': getCTAs.length}"
  >
    <ClientOnly v-if="videoId">
      <MProductVideo
        :video-id="videoId"
        :auto-play="true"
        :hero-banner-video="true"
      />
    </ClientOnly>
    <MImage v-else
            :src="getLazyLoadImage"
            :alt="desktopImage && desktopImage.alt"
            :backdrop="false"
            :picture-breakpoint="601"
            :class="{'no-opacity': index === 0}"
            :lazy="false"
            :setpriority="index === 0"
    />
    <link
      v-if="index === 0"
      fetchpriority="high"
      rel="preload"
      :href="getLazyLoadImage.desktop.url"
      as="image"
    >
    <link
      v-if="index === 0"
      fetchpriority="high"
      rel="preload"
      :href="getLazyLoadImage.mobile.url"
      as="image"
    >
    <div
      class="cms-hero-banner__content"
      :class="alignment"
      :style="getStyles"
    >
      <div>
        <div v-if="getCountdownTime">
          <ACountDown :countdown-end-time="getCountdownTime" :countdown-text="getCountdownText" />
        </div>
        <h2
          v-if="title"
          class="cms-hero-banner__content-title"
          :style="getStylesTitle"
        >
          {{ title }}
        </h2>
        <AAlert
          v-if="copiedToClipboard"
          :message="`${code}` + $t(' Copied!')"
          class="copy-to-clipboard-alert"
        />
        <div
          v-if="text"
          class="cms-hero-banner__content-text paragraph-1"
          :class="richTextClassName"
          v-html="renderRichText(text)"
          :style="getStylesTitle"
        />
        <div
          v-if="getCTAs.length"
          @click.stop="handleHeroBannerClick"
        >
          <CTA
            v-for="cta in getCTAs"
            :key="cta._uid"
            :editable="editable"
            :text="cta.text"
            :link="getCtaLink(cta)"
            :link-type="cta.link.linktype"
            :target="cta.target"
            :layout-option="cta.layout_option"
            :outlined="cta.outlined"
            @click.native="handleHeroBannerCtaClick({ URL: getCtaLink(cta), name: title , position })"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RichText from '../mixins/RichText';
import ImageResize from '../mixins/ImageResize';
import MImage from 'src/common/components/molecules/m-image.vue';
import CTA from 'src/modules/storyblok/components/CTA';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import config from 'config'
import AAlert from 'common/components/atoms/a-alert';
import ACountDown from 'src/common/components/atoms/a-count-down.vue';
import MProductVideo from 'common/components/molecules/product-details/m-product-video.vue';
import ClientOnly from 'vue-client-only';

export default {
  name: 'HeroBannerBlock',
  components: {
    MImage,
    CTA,
    AAlert,
    ACountDown,
    MProductVideo,
    ClientOnly
  },
  data: () => ({
    copiedToClipboard: false,
    code: ''
  }),
  mixins: [RichText, ImageResize],
  props: {
    isHome: {
      type: Boolean,
      default: false
    },
    blockId: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    desktopImage: {
      type: Object,
      default: () => {}
    },
    mobileImage: {
      type: Object,
      default: () => {}
    },
    text: {
      type: Object,
      default: () => {}
    },
    textColor: {
      type: String,
      default: ''
    },
    position: {
      type: Number,
      default: 1
    },
    editable: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 10
    },
    observeIndividualItem: {
      type: Boolean,
      default: false
    },
    countdownEndTime: {
      type: Array,
      default: () => []
    },
    alignment: {
      type: String,
      default: 'center'
    },
    videoId: {
      type: String,
      default: ''
    }
  },
  computed: {
    richTextClassName () {
      return `rich-text-${this.position}`
    },
    getStyles () {
      return {
        '--cms-hero-banner-color': this.textColor
      };
    },
    getStylesTitle () {
      return {
        '--cms-hero-banner__content-title': this.textColor,
        'color': this.textColor
      };
    },
    getButtonStyle () {
      return 'primary';
    },
    getDesktopImage () {
      return this.getImage(`${config?.storyblok?.image?.desktop?.heroBanner?.width}x0`, this.desktopImage);
    },
    getMobileImage () {
      return this.getImage(`${config?.storyblok?.image?.mobile?.heroBanner?.width}x0`, this.mobileImage || null)
    },
    getCTAs () {
      return this?.editable?.CTA || [];
    },
    getCountdownTime () {
      return this.countdownEndTime[0]?.end_time || ''
    },
    getCountdownText () {
      return this.countdownEndTime[0]?.countdown_timer_text || ''
    }
  },
  mounted () {
    if (!this.observeIndividualItem) return;

    this.observer = new IntersectionObserver(
      (entries) => {
        const blockEl = entries[0];
        if (blockEl.isIntersecting) {
          EventBus.$emit('home-block-promo-view', {
            id: this.blockId,
            name: this.title,
            creative: null,
            position: null
          });
          this.observer.disconnect();
        }
      },
      {
        threshold: 0.7
      }
    );
    this.copyToClipboardHandler();

    setTimeout(() => {
      this.observer.observe(this.$el);
    }, 500);
  },
  destroyed () {
    if (!this.observeIndividualItem) return;
    this.observer.disconnect();
  },
  methods: {
    copyToClipboardHandler () {
      document.querySelectorAll(`.${this.richTextClassName} a[href="#copyToClipboard"]`).forEach((element) => {
        let timer = null;
        if (element && element.getAttribute('listener') !== 'true') {
          element.setAttribute('href', 'javascript:;')
          element.setAttribute('listener', 'true');
          element.addEventListener('click', (event) => {
            event.stopPropagation();
            this.code = element.innerText;
            navigator.clipboard.writeText(element.innerText);

            this.copiedToClipboard = true;
            if (timer) { clearTimeout(timer) }
            timer = setTimeout(() => {
              this.copiedToClipboard = false;
              this.code = '';
            }, 2500);
          });
        }
      })
    },
    getCtaLink (cta) {
      if (!cta) return '';
      const linkObj = cta?.link || {};
      return linkObj?.url || linkObj?.cached_url;
    },
    handleHeroBannerCtaClick (data) {
      const { URL, name, position } = data
      EventBus.$emit('homepage_click', {
        URL,
        homepage_id: this.blockId,
        name,
        position
      });
    },
    handleHeroBannerClick () {
      EventBus.$emit('home-block-item-clicked', {
        id: 'homepage_hero_carousel',
        name: this.title,
        creative: this.desktopImage?.filename,
        position: this.position
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "src/common/assets/css/storyblok/lazyload";
@import "src/common/assets/css/copytoclipboard.scss";

.cms-hero-banner {
  position: relative;

  &__image {
    width: 100%;
  }

  &__content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    width: 100%;
    height: 100%;
    color: var(--cms-hero-banner-color, var(--text-color-primary));
    text-align: center;
    padding: 0 1.5rem;
    box-sizing: border-box;
    &.left {
      justify-content: left;
      text-align: left;
      padding: 0 20px;
      @include for-desktop {
        padding: 0 80px;
      }
      ::v-deep {
        .cms-divider-cta {
          justify-content: left;
          padding-left: 0;
        }
      }
    }
    ::v-deep {
      .cms-divider-cta {
        margin-top: 20px;
    }
    }
    &-title {
      margin: 0 0 1.5rem;
      font-weight: 500;
      color: var(--cms-hero-banner__content-title, var(--text-color-primary)) !important;
    }

    &-text {
      max-width: 500px;
      margin: 0 0 1.5rem;
      color: var(--cms-hero-banner__content-title, var(--text-color-primary)) !important;
      ::v-deep {
        span, p {
        color: unset;
      }
      }
      @include for-desktop {
        margin: 0 auto;
      }
    }
  }

  ::v-deep .sf-button {
    --button-display: inline-block;
      width: auto;
  }

  ::v-deep .loaded {
    @include lazyload-image-loaded;
  }

  ::v-deep .m-image {
    padding-top: 0 !important;
    height: unset;
    max-height: unset;
    @include for-desktop {
      height: unset;
      max-height: unset;
    }
    @include lazyload-image;
  }
  ::v-deep .no-opacity {
    img {
      opacity: 1 !important;
    }
  }

  ::v-deep .countdown-timer {
    .countdown-timer-text {
      margin-bottom: 20px;
      font-size: 18px;
      text-align: center;
      @include for-mobile{
        font-size: 16px;
      }
    }
    .countdown-container {
  display: flex;
  gap: 4px;
  padding: 0px 12px;
  justify-content: center;
  margin-bottom: 32px;
  .transition-container {
    display: flex;
    gap: 1px;
    .transition-item {
      background-color: var(--white);
      width: 50px;
      height: 70px;
      padding: 0px;
      text-align: center;
      border-radius: 5px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      font-size: 28px;
      font-weight: 700;
      color: var(--theme-text);
      font-family: PoynterOSDisp;
      display: flex;
      align-items: center;
      justify-content: center;
      @include for-mobile{
        width: 44px;
        height: 60px;
        line-height: 30px;
        font-size: 24px;
      }
    }
  }
  .time-left-item-text {display: none;}
  .divider-item {
      width: 25px;
      height: 70px;
      padding: 0px;
      text-align: center;
      border-radius: 5px;
      font-size: 32px;
      font-weight: 700;
      color: var(--theme-text);
      font-family: PoynterOSDisp;
      line-height: 2;
       @include for-mobile{
        font-size: 28px;
        width: 20px;
        height: 60px;
        padding: 0px;
      }
  }
  .time-left-container {
    .content {
      text-align: center;
      margin-top: 12px;
      font-size: var(--font_size_75);
      letter-spacing: 1.4px;
    }
  }
  .countdown-fade-enter-active, .countdown-fade-leave-active {
  transition: transform 0.5s;
}

.countdown-fade-enter, .countdown-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.countdown-seconds-fade-enter-active, .countdown-seconds-fade-leave-active {
  transition: transform 0.5s;
}

.countdown-seconds-fade-enter, .countdown-seconds-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.countdown-digit {
  color: var(--neutral-900);
  display: inline-block;
}

.countdown-seconds-item {
  display: inline-block;
}
}
  }

}

.cms-hero-banner-home {
  ::v-deep .m-image {
    padding-top: 0 !important;
    min-height: 464px;

    picture img {
      min-height: 464px;

    }
    @include for-desktop {
      min-height: 600px;

      picture img {
        min-height: 600px;

      }
    }
    @include lazyload-image;
  }
  @include copySuccessAlert;
  @include copyToClipboard;

  &.clickable-banner {
    cursor: pointer;
  }
}
</style>
