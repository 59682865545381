<template>
  <div :class="isError ? 'error-alert' : 'success-alert'" transition="sf-expand">
    {{ $t(message) }}
  </div>
</template>

<script>
export default {
  name: 'AAlert',
  props: {
    isError: {
      default: false,
      type: Boolean
    },
    message: {
      default: null,
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.error-alert {
  font-size: var(--font_size_50);
  color: var(--danger-500);
  background-color: var(--danger-200);
  text-align: center;
  padding: var(--spacer-xs);
}
.success-alert {
  font-size: var(--font_size_50);
  color: var(--success-800);
  background-color: var(--alert-success);
  text-align: center;
  padding: var(--spacer-xs);
}
</style>
