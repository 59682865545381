<template>
  <div>
    <template v-if="getCTAs.length">
      <a :href="getExternalLink" @click="handleHeroBannerClick" v-if="isExternalLink" :target="target">
        <HeroBannerBlock
          :is-home="isHome"
          :block-id="blockId"
          :title="title"
          :desktop-image="desktopImage"
          :mobile-image="mobileImage"
          :text="text"
          :text-color="textColor"
          :position="position"
          :editable="editable"
          :index="index"
          :observe-individual-item="observeIndividualItem"
          :countdown-end-time="countdownEndTime"
          :alignment="alignItems"
          :video-id="videoId"
        />
      </a>
      <router-link
        :to="localizedRoute(getStoryLink)"
        v-else-if="isLinkTypeStory"
        @click.native="handleHeroBannerClick"
      >
        <HeroBannerBlock
          :is-home="isHome"
          :block-id="blockId"
          :title="title"
          :desktop-image="desktopImage"
          :mobile-image="mobileImage"
          :text="text"
          :text-color="textColor"
          :position="position"
          :editable="editable"
          :index="index"
          :observe-individual-item="observeIndividualItem"
          :countdown-end-time="countdownEndTime"
          :alignment="alignItems"
          :video-id="videoId"
        />
      </router-link>
      <router-link
        :to="localizedRoute(getDynamicLink)"
        v-else-if="isInternalLink"
        @click.native="handleHeroBannerClick"
      >
        <HeroBannerBlock
          :is-home="isHome"
          :block-id="blockId"
          :title="title"
          :desktop-image="desktopImage"
          :mobile-image="mobileImage"
          :text="text"
          :text-color="textColor"
          :position="position"
          :editable="editable"
          :index="index"
          :observe-individual-item="observeIndividualItem"
          :countdown-end-time="countdownEndTime"
          :video-id="videoId"
        />
      </router-link>
    </template>
    <template v-else>
      <HeroBannerBlock
        :is-home="isHome"
        :block-id="blockId"
        :title="title"
        :desktop-image="desktopImage"
        :mobile-image="mobileImage"
        :text="text"
        :text-color="textColor"
        :position="position"
        :editable="editable"
        :index="index"
        :observe-individual-item="observeIndividualItem"
        :countdown-end-time="countdownEndTime"
        :video-id="videoId"
      />
    </template>
  </div>
</template>

<script>
import config from 'config'
import HeroBannerBlock from './HeroBannerBlock.vue';
import matchMedia from 'common/mixins/matchMedia';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';

export default {
  name: 'HeroBanner',
  components: {
    HeroBannerBlock
  },
  mixins: [matchMedia],
  props: {
    isHome: {
      type: Boolean,
      default: false
    },
    blockId: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    desktopImage: {
      type: Object,
      default: () => {}
    },
    mobileImage: {
      type: Object,
      default: () => {}
    },
    text: {
      type: Object,
      default: () => {}
    },
    textColor: {
      type: String,
      default: ''
    },
    position: {
      type: Number,
      default: 1
    },
    editable: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 10
    },
    observeIndividualItem: {
      type: Boolean,
      default: false
    },
    countdownEndTime: {
      type: Array,
      default: () => []
    },
    alignItems: {
      type: String,
      default: 'center'
    },
    mobileVideoId: {
      type: String,
      default: ''
    },
    desktopVideoId: {
      type: String,
      default: ''
    }
  },
  computed: {
    videoId () {
      if (this.mobileVideoId && this.isMobile) {
        return this.mobileVideoId;
      }
      if (this.desktopVideoId && !this.isMobile) {
        return this.desktopVideoId;
      }
      return null;
    },
    getCTAs () {
      return this?.editable?.CTA || [];
    },
    firstCta () {
      return this.getCTAs[0] || [];
    },
    isExternalLink () {
      return !this.isLinkTypeStory && !this.isInternalLink;
    },
    isLinkTypeStory () {
      return this.firstCta?.link?.linktype === 'story';
    },
    isInternalLink () {
      return this.getCtaLink(this.firstCta).includes('{store}');
    },
    getExternalLink () {
      return this.link;
    },
    target () {
      return this.firstCta.target || '_self';
    },
    link () {
      return this.getCtaLink(this.firstCta);
    },
    isLocalStory () {
      return this.link.includes(`/${config?.storyblok?.localFolder}/`);
    },
    getStoryLink () {
      const localFolder = `/${config?.storyblok?.localFolder}/`;

      if (this.isLocalStory) {
        const result = this.link?.split(localFolder);
        return `${localFolder}${result[1]}`;
      }

      return this.link;
    },
    getDynamicLink () {
      return this.link.replace('{store}', '');
    }
  },
  methods: {
    getCtaLink (cta) {
      if (!cta) return '';
      const linkObj = cta?.link || {};
      return linkObj?.url || linkObj?.cached_url;
    },
    handleHeroBannerClick () {
      EventBus.$emit('homepage_click', {
        URL: this.getCtaLink(this.firstCta),
        homepage_id: this.blockId,
        name: this.name || '',
        position: this.position
      });
    }
  }
};
</script>
