<template>
  <div class="countdown-timer">
    <div class="countdown-timer-text" v-if="countdownText">
      {{ countdownText }}
    </div>
    <div class="countdown-container">
      <!-- days transition  -->
      <div class="time-left-container">
        <div class="transition-container">
          <div class="transition-item">
            <transition name="countdown-fade" mode="out-in">
              <div :key="`${getFirstNumber(countdown.days)}-first-number`" class="countdown-item">
                <div class="countdown-digit">
                  {{ getFirstNumber(countdown.days) }}
                </div>
              </div>
            </transition>
          </div>
          <div class="transition-item">
            <transition name="countdown-fade" mode="out-in">
              <div :key="`${getSecondNumber(countdown.days)}-second-number`" class="countdown-item">
                <div class="countdown-digit">
                  {{ getSecondNumber(countdown.days) }}
                </div>
              </div>
            </transition>
          </div>
          <div class="time-left-item-text">
            Days
          </div>
        </div>
        <div class="content">
          DAYS
        </div>
      </div>

      <div class="divider-item">
        :
      </div>

      <!-- hours transition  -->
      <div class="time-left-container">
        <div class="transition-container">
          <div class="transition-item">
            <transition name="countdown-fade" mode="out-in">
              <div :key="`${getFirstNumber(countdown.hours)}-first-number`" class="countdown-item">
                <div class="countdown-digit">
                  {{ getFirstNumber(countdown.hours) }}
                </div>
              </div>
            </transition>
          </div>
          <div class="transition-item">
            <transition name="countdown-fade" mode="out-in">
              <div :key="`${getSecondNumber(countdown.hours)}-second-number`" class="countdown-item">
                <div class="countdown-digit">
                  {{ getSecondNumber(countdown.hours) }}
                </div>
              </div>
            </transition>
          </div>
          <div class="time-left-item-text">
            Hrs
          </div>
        </div>
        <div class="content">
          HOURS
        </div>
      </div>

      <div class="divider-item">
        :
      </div>

      <!-- minutes transition  -->
      <div class="time-left-container">
        <div class="transition-container">
          <div class="transition-item">
            <transition name="countdown-fade" mode="out-in">
              <div :key="`${getFirstNumber(countdown.minutes)}-first-number`" class="countdown-item">
                <div class="countdown-digit">
                  {{ getFirstNumber(countdown.minutes) }}
                </div>
              </div>
            </transition>
          </div>

          <div class="transition-item">
            <transition name="countdown-fade" mode="out-in">
              <div :key="`${getSecondNumber(countdown.minutes)}-second-number`" class="countdown-item">
                <div class="countdown-digit">
                  {{ getSecondNumber(countdown.minutes) }}
                </div>
              </div>
            </transition>
          </div>
          <div class="time-left-item-text">
            Mins
          </div>
        </div>
        <div class="content">
          MINUTES
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ACountDown',
  data () {
    return {
      endDate: null,
      startDate: null,
      countdown: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    };
  },
  props: {
    countdownEndTime: {
      type: String,
      default: ''
    },
    countdownText: {
      type: String,
      default: ''
    }
  },
  async created () {
    if (!this.getServerTime?.serverTime) {
      await this.fetchServerTime();
    }
    if (this.getServerTime?.serverTime) {
      const currentTime = new Date();
      const timeDifference = currentTime - this.getServerTime.timeWhenServerTimeFetched;
      this.startDate = new Date(this.getServerTime.serverTime.getTime() + timeDifference);
    } else {
      this.startDate = new Date();
    }
    const convertedDateString = this.countdownEndTime.replace(' ', 'T') + ':00.000Z'; // as date is in utc, convert in into iso format
    this.endDate = new Date(convertedDateString);
    this.calculateCountdown();
  },
  mounted () {
    setInterval(this.calculateCountdown, 60000); // set the interval after one minute
  },
  computed: {
    ...mapGetters({
      getServerTime: 'catalog-es/getServerTime'
    })
  },
  methods: {
    ...mapActions({
      fetchServerTime: 'catalog-es/fetchServerTime'
    }),
    async calculateCountdown () {
      this.startDate.setMinutes(this.startDate.getMinutes() + 1);
      const timeDifference = this.endDate - this.startDate;
      if (timeDifference > 0) {
        this.countdown.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        this.countdown.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.countdown.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        this.countdown.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
      } else {
        this.countdown.days = 0;
        this.countdown.hours = 0;
        this.countdown.minutes = 0;
        this.countdown.seconds = 0;
      }
    },
    getFirstNumber (data) {
      if (data.toString().length === 1) {
        return 0
      }
      return data.toString()[0];
    },
    getSecondNumber (data) {
      if (data.toString().length === 1) {
        return data.toString()[0];
      }
      return data.toString()[1];
    }
  }
};
</script>
