<template>
  <div class="video-container">
    <div
      v-show="!playing"
      class="video-thumbnail-container desktop-only"
    >
      <img
        :src="videoPlaceHolder"
        :alt="'video_text_overlay_text_bg'"
        class="poster-image"
        height="300"
        width="500"
      >
    </div>
    <video
      v-show="playing && hlsPlayer"
      id="cf-player"
      ref="cfPlayerRef"
      loop
      playsinline
      muted
      :poster="videoPlaceHolder"
      @ended="videoEnded"
      onclick="this.paused ? this.play() : this.pause(); arguments[0].preventDefault();"
    />
  </div>
</template>

<script>
import windowSize from 'src/common/mixins/windowSize';
import { loadScript } from 'src/modules/storyblok/helpers';
import config from 'config'
import videoPlaceHolder from 'common/assets/videoplaceholder.png'

export default {
  name: 'MProductVideo',
  mixins: [windowSize],
  props: {
    thumbnail: {
      type: Object,
      default: () => ({})
    },
    videoId: {
      type: String,
      default: ''
    },
    poster: {
      type: String,
      default: ''
    },
    autoPlay: {
      type: Boolean,
      default: false
    },
    heroBannerVideo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hlsPlayer: null,
      cfPlayerRef: null,
      loadingScripts: false,
      playing: false,
      videoPlaceHolder
    };
  },
  mounted () {
    if (this.autoPlay) {
      this.playVideo()
    }
  },
  methods: {
    playVideo () {
      this.loadingScripts = true;
      const cdnLink = config.HLS_PLAYER.CDN_LINK
      const hlsVideoUrl = config.HLS_PLAYER.VIDEO_URL.replace('{{videoId}}', this.videoId)
      loadScript(cdnLink, 'hls-player').then(
        () => {
          this.loadingScripts = false;
          this.hlsPlayer = new window['Hls']();
          this.hlsPlayer?.loadSource(hlsVideoUrl);
          this.cfPlayerRef = this.$refs?.cfPlayerRef
          if (!this.isMobileDevice && !this.heroBannerVideo) {
            const container = document.querySelector('.product__gallery li:nth-child(1) > div')
            this.cfPlayerRef.height = container?.clientHeight
          }
          const isiPhone = /iPhone/i.test(navigator.userAgent);
          if (isiPhone) {
            this.cfPlayerRef.src = hlsVideoUrl
          } else {
            this.hlsPlayer.attachMedia(this.cfPlayerRef);
          }
          this.cfPlayerRef.playsInline = true
          this.cfPlayerRef.muted = true
          this.cfPlayerRef.play();
          this.playing = true
          this.$bus.$emit('video-played', {})
        }
      );
    },
    videoEnded () {
      if (this.isMobileDevice) {
        this.$emit('videoEnded')
        this.playing = false
      }
    }
  }
};
</script>
  <style lang="scss" scoped>
  @import "~@storefront-ui/shared/styles/helpers/breakpoints";

  ::v-deep .sf-loader {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
  }
  ::v-deep .sf-loader__overlay {
    background: transparent;
  }
  .video-container {
    display: flex;
    position: relative;
    justify-content: center;
    height: 100%;

    .video-thumbnail-container {
      position: relative;
      width: 100%;
      .poster-image {
        width: 100%;
        height: auto;
        @include for-mobile {
          max-width: 400px;
          display: flex;
          margin: auto;
          margin-top: 30px;
        }
      }
      .video-thumbnail-content {
        filter: drop-shadow(0 0 0.1rem gray);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .play-btn {
          cursor: pointer;
          stroke: white;
          width: 58px;
          height: 58px;
        }
      }
    }
    #cf-player {
      position: relative;
      width: 100%;
      @include for-mobile {
        height: 65vh;
        margin-top: 10px;
        max-width: 400px;
      }
    }
  }
  video::-webkit-media-controls-panel {
    display: none !important;
    opacity: 1 !important;
  }
  </style>
